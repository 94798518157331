@import './vars';

.welcome {
  height: 100vh;
  min-height: 100vh;
  width: 100vw;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('assets/welcome.jpg') no-repeat;
    background-size: cover;
    -webkit-animation: kenburns-bottom-right 35s ease-out infinite alternate both;
    animation: kenburns-bottom-right 35s ease-out infinite alternate both;
    filter: sepia(80%);
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAFklEQVQYV2NkwAIYyRZsAOlE1w4WBAARJAEGRYo3AwAAAABJRU5ErkJggg==') repeat;
  }

  .welcome-data {
    position: absolute;
    background-color: #fff;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.95;
    padding: 2rem 4rem;
    width: 50vw;

    h1 {
      color: $color-primary;
      font-weight: 300;
    }

    p {
      color: $color-dark;
    }


    .error {
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: $color-primary;
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 600px) {
  .welcome {
    .welcome-data {
      width: 100vw;
    }
  }

}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-10 12:39:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation kenburns-bottom-right
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-bottom-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 84%;
    transform-origin: 84% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(20px, 15px);
    transform: scale(1.25) translate(20px, 15px);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}

@keyframes kenburns-bottom-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 84%;
    transform-origin: 84% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(20px, 15px);
    transform: scale(1.25) translate(20px, 15px);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}
