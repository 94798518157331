@import '../vars';

.userinput {
  background-color: $color-quitewhite;
  width: 100%;
  margin-bottom:0.5rem;

  label {
    font-size: 0.7rem;
    color: $color-primary;
    padding-left:4px;
  }

  input {
    padding:4px;
    border: 0;
    outline: none;
    background-color: transparent;
    width: 100%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $color-quitewhite inset !important;
  }

}