@import '../vars';

.dropdown {
  background-color: $color-quitewhite;
  width: 100%;

  label {
    font-size: 0.7rem;
    color: $color-primary;
    padding-left:4px;
  }

  input {
    padding:4px;
    border: 0;
    outline: none;
    background-color: transparent;
    width: 100%;
  }
}

.dropdown-menu {
  background-color:#fff;
  z-index: 10000;
  border-radius: 2px;
  box-shadow: 0 2px 4px 2px rgba(0,0,0,0.25);
  position:absolute;
  display:flex;
  flex-direction: column;

  .dropdown-item {
    font-size:0.9rem;
    padding:3px 8px;
    cursor:pointer;
    &:hover {
      background-color: $color-primary;
      color: $color-quitewhite;
    }
  }
}