@import '../vars';

.buttonlist {
  button {
    background-color:transparent;
    border: 1px solid $color-dark;
    color:$color-dark;
    padding:4px 6px;
    border-radius: 4px;
    margin-right: 2px;
    margin-bottom: 2px;
    cursor: pointer;

    &.highlight {
      border: 1px solid $color-primary;
      background-color:$color-primary;
      color:$color-quitewhite;
    }
  }
}