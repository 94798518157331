$color-primary: #D62828;
$color-dark: #666;
$color-gray: #ccc;
$color-nearwhite: #e5e5e5;
$color-quitewhite: #f5f5f5;


button {
  cursor: pointer;
  background-color: $color-primary;
  color: #fff;
  padding: 1rem;
  border-radius: 4px;
  border: 0;
}

button[disabled] {
  background-color: $color-nearwhite;
  color: $color-gray;
}
