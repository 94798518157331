@import './vars';

.application {

  .row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }

  .clear {
    cursor:pointer;
    padding:4px 6px;
    color: $color-primary;
    font-size:0.85rem;
    margin-bottom:1rem;
  }

  h1 {
    font-weight: 300;

    small {
      display: block;
      font-size: 1rem;
    }
  }

  h3 {
    font-weight: normal;
  }

  .description {
    font-size: 1rem;
    color: $color-dark;
    margin-bottom: 1rem;
  }

  .missing {
    font-size: 0.8rem;
    color: $color-primary;
    margin: 1rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;

    svg {
      width: 18px;
      height: 18px;
      margin-right: .5rem;
    }
  }

  .alignRight {
    text-align: right;
  }

  .cell {
    margin-bottom: 0.3rem;
  }

  .pb {
    padding-bottom: 1rem;
  }

  .submit {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 0.8rem;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem 4rem;
    border-top: 1px solid $color-gray;
    background-color: #fff;
  }

  margin: 0;
  padding: 2rem 4rem;
}

@media (max-width: 600px) {
  .application {
    padding: 1rem;
    padding-bottom:3rem;

    .submit {
      padding: 1rem;
      font-size:0.75rem;
    }
  }
}

:focus {
  outline: none;
}